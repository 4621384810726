<template>
  <div>
    <div class="card rounded-0 border-0 bg-white px-4 pt-3 pb-6">
      <div class="card-header p-0 bg-transparent">
        <h5 class="card-title text-capitalize">Profile Logo</h5>
      </div>
      <div class="card-body px-0 pt-4 pb-0">
        <div class="profile media d-flex align-items-center flex-wrap">
          <div class="image mb-4">
            <img
              :src="user ? user.profileImageUrl : null"
              :alt="user ? user.fullName : null"
               class="card rounded-circle image-box-style-card box-shadow-hover p-1"
                                style="border:1px solid #CCCCCC !important;border-radius: 50%;"
            />
          </div>
          <div class="media-body d-flex flex-wrap ml-0">
            <div class="upload-btn-wrapper mr-4 mb-4">
              <button
                @click="toggleShowModal"
                class="btn btn-primary px-4 font-size-md"
              >
                Upload New Picture
              </button>

            
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showModal">
      <my-upload
                field="profileImage"
                @crop-upload-success="cropUploadSuccess"
                @crop-upload-fail="cropUploadFail"
                v-model="showModal"
                :width="300"
                :height="300"
                :url="url"
                :params="params"
                :headers="headers"
                lang-type="en"
                img-format="png"
                 :no-square="true"
            ki="0"
              ></my-upload>
              </div>
  </div>
  
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import "babel-polyfill"; // es6 shim
import myUpload from "vue-image-crop-upload";
export default {
  name: "ProfileLogo",
  data() {
    return {
      showModal: false,
      url: process.env.VUE_APP_API_URL + "addProfileImage",
      params: {
        token: `Bearer ${localStorage.getItem("authToken")}`,
        name: "profileImage",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        smail: "*_~",
      },
      imgDataUrl: "", // the datebase64 url of created image,
    };
  },

  components: {
    "my-upload": myUpload,
  },
  mounted() {
    this.$store.commit("setErrors", {});
    this.$store.commit("setIsLoading", false);
    this.$store.commit("setIsSuccess", false);
  },
  computed: {
    ...mapGetters("auth", ["user", "errors"]),
  },
  methods: {
    ...mapActions("auth"),

    toggleShowModal() {
      this.showModal = !this.showModal;
    },
  
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData) {
    this.user.profileImageUrl=jsonData.data;
      this.showModal = !this.showModal;
 this.$notify({
            // (optional)
            // Name of the notification holder
            group: "app",

            // (optional)
            // Class that will be assigned to the notification
            type: "success",

            // (optional)
            // Title (will be wrapped in div.notification-title)
            title: "Update",

            // Content (will be wrapped in div.notification-content)
            text: "Profile logo updated successfully",

            // (optional)
            // Overrides default/provided duration
            duration: 10000,

            // (optional)
            // Overrides default/provided animation speed
            speed: 1000,
          });
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail() {
       this.$notify({
            // (optional)
            // Name of the notification holder
            group: "app",

            // (optional)
            // Class that will be assigned to the notification
            type: "warn",

            // (optional)
            // Title (will be wrapped in div.notification-title)
            title: "Update",

            // Content (will be wrapped in div.notification-content)
            text: "Profile not updated, try again!",

            // (optional)
            // Overrides default/provided duration
            duration: 10000,

            // (optional)
            // Overrides default/provided animation speed
            speed: 1000,
          });
    },
  },
};
</script>
