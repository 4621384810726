<template>
  <div>
    <div class="card rounded-0 border-0 bg-white px-4 pt-3 pb-6">
      <div class="card-header p-0 bg-transparent">
        <h5 class="card-title text-capitalize">Profile Details</h5>
      </div>
      <div class="card-body px-0 pt-4 pb-0">
        <div class="">
          <form>
             <div class="form-group mb-2">
                    <label for="firstName" class="sr-only">First name</label>
                    <input id="firstName" type="text" class="form-control font-size-lg  form-control-lg bg-transparent" :class="{'is-invalid': errors.firstName}" v-model="user.firstName" placeholder="Enter your first name" :disabled="isLoading ? '': isLoading" />

                    <div class="invalid-feedback" v-if="errors.firstName">
                      {{ errors.firstName[0] }}
                    </div>

                  </div>

                   <div class="form-group mb-2">
                    <label for="lastName" class="sr-only">Last name</label>
                    <input id="lastName" type="text" class="form-control font-size-lg  form-control-lg bg-transparent" :class="{'is-invalid': errors.lastName}" v-model="user.lastName" placeholder="Enter your last name" :disabled="isLoading ? '': isLoading" />

                    <div class="invalid-feedback" v-if="errors.lastName">
                      {{ errors.lastName[0] }}
                    </div>

                  </div>
            <div class="form-row mb-2">
              <div class="col-sm-12 mb-2 mb-sm-0">
                <label
                  for="email"
                  class="font-size-md text-dark font-weight-semibold mb-1"
                  >Email Address <span class="text-danger">*</span></label
                >
                <input
                  class="form-control font-size-lg  form-control-lg bg-transparent"
                  id="email"
                  type="text"
                  :class="{ 'is-invalid': errors.email }"
                  v-model="user.email"
                />
              </div>
            </div>

            <div class="form-group mt-6" v-if="errors.message">
              <div class="alert alert-danger">
                {{ errors.message }}
              </div>
            </div>
            <button
              type="button"
              @click="updateProfile"
              :disabled="isLoading ? '' : isLoading"
              class="btn btn-primary btn-block font-weight-bold  font-size-lg rounded-sm"
            >
              <span v-if="!isLoading"> Save Change</span>
              <span v-if="isLoading">
                <i class="fas fa-circle-notch fa-spin"></i> Saving...</span
              >
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Detail",

  mounted() {
    this.$store.commit("setErrors", {});
    this.$store.commit("setIsLoading", false);
    this.$store.commit("setIsSuccess", false);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["errors", "isLoading", "isSuccess"]),
  },
  methods: {
    ...mapActions("auth", ["sendUpdateProfileRequest"]),

    updateProfile: function() {
      this.sendUpdateProfileRequest(this.user).then(() => {
        if (this.isSuccess) {
          this.$store.commit("setErrors", {});
          this.$store.commit("setIsLoading", false);
          this.$store.commit("setIsSuccess", false);
          this.$notify({
            // (optional)
            // Name of the notification holder
            group: "app",

            // (optional)
            // Class that will be assigned to the notification
            type: "success",

            // (optional)
            // Title (will be wrapped in div.notification-title)
            title: "Update",

            // Content (will be wrapped in div.notification-content)
            text: "Profile details updated successfully",

            // (optional)
            // Overrides default/provided duration
            duration: 10000,

            // (optional)
            // Overrides default/provided animation speed
            speed: 1000,
          });
        }
      });
    },
  },
};
</script>
