<template>
  <div>
    <div class="card rounded-0 border-0 bg-white px-4 pt-3 pb-6">
      <div class="card-header p-0 bg-transparent">
        <h5 class="card-title text-capitalize">Delete Account</h5>
      </div>
      <div class="card-body px-0 pt-4 pb-0">
        <div class="profile media d-flex align-items-center flex-wrap">
          <div class="media-body d-flex flex-wrap ml-0">
            <button
              type="button"
              @click="deleteNow"
              :disabled="isLoadingX ? '' : isLoadingX"
              class="btn btn-block btn-darker-light mb-4 font-size-md"
            >
              <span v-if="!isLoadingX"> Delete </span>
              <span v-if="isLoadingX">
                <i class="fas fa-circle-notch fa-spin"></i> Deleting...</span
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DeleteAccount",
  data: function() {
    return {
      isLoadingX: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["deleteAccount"]),

    deleteNow: function() {
      // if(confirm("Are you sure you want to delete your account?")==1){
      this.isLoadingX = true;
      this.deleteAccount().then(() => {
        this.isLoadingX = false;
        this.$router.push({
          name: "Login",
        });
      });

      // }
    },
  },
};
</script>
