<template>
  <div>
    <div class="card rounded-0 border-0 bg-white px-4 pt-3 pb-6">
      <div class="card-header p-0 bg-transparent">
        <h5 class="card-title text-capitalize">Change Password</h5>
      </div>
      <div class="card-body px-0 pt-4 pb-0">
        <div class="form-update-profile">
          <form>
            <div class="form-group mb-3">
              <label for="current_password" class="sr-only"
                >Current Password</label
              >

              <input
                id="current_password"
                type="password"
                class="form-control font-size-lg  form-control-lg bg-transparent"
                :class="{ 'is-invalid': changePasswordErrors.current_password }"
                v-model="details.current_password"
                :disabled="isLoading ? '' : isLoading"
                placeholder="Current Password"
              />

              <div class="invalid-feedback" v-if="changePasswordErrors.current_password">
                {{ changePasswordErrors.current_password[0] }}
              </div>
            </div>

            <div class="form-group mb-3">
              <label for="password" class="sr-only">New Password</label>

              <input
                id="password"
                type="password"
                class="form-control font-size-lg  form-control-lg bg-transparent"
                :class="{ 'is-invalid': changePasswordErrors.password }"
                v-model="details.password"
                :disabled="isLoading ? '' : isLoading"
                placeholder="Password"
              />

              <div class="invalid-feedback" v-if="changePasswordErrors.password">
                {{ changePasswordErrors.password[0] }}
              </div>
            </div>

            <div class="form-group mb-3">
              <label for="password_confirmation" class="sr-only"
                >Confirm password</label
              >

              <input
                id="password_confirmation"
                type="password"
                class="form-control font-size-lg  form-control-lg bg-transparent"
                :class="{ 'is-invalid': changePasswordErrors.password_confirmation }"
                v-model="details.password_confirmation"
                :disabled="isLoading ? '' : isLoading"
                placeholder="Confirm password"
              />

              <div class="invalid-feedback" v-if="changePasswordErrors.password_confirmation">
                {{ changePasswordErrors.password_confirmation[0] }}
              </div>
            </div>
            <div class="form-group mt-6" v-if="changePasswordErrors.message">
              <div class="alert alert-danger">
                {{ changePasswordErrors.message }}
              </div>
            </div>
            <button
              type="button"
              @click="changePassword"
              :disabled="isLoading ? '' : isLoading"
              class="btn btn-primary btn-block font-weight-bold  font-size-lg rounded-sm"
            >
              <span v-if="!isLoading"> Change Password</span>
              <span v-if="isLoading">
                <i class="fas fa-circle-notch fa-spin"></i> Updating...</span
              >
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ChangePassword",

  data: function() {
    return {
      isLoading:false,
      details: {
        current_password: null,
        password: null,
        password_confirmation: null,
      },
    };
  },

  mounted() {
    this.$store.commit("setChangePasswordErrors", {});
    this.isLoading =false;
    this.$store.commit("setChangePasswordSuccess", false);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["changePasswordErrors", "changePasswordSuccess"]),
  },
  methods: {
    ...mapActions("auth", ["sendChangePasswordRequest"]),

    changePassword: function() {
       this.isLoading =true;
      this.sendChangePasswordRequest(this.details).then(() => {
         this.isLoading =false;
         
           this.$store.commit("setErrors", {});
        if (this.changePasswordSuccess) {
          this.details={};
          this.$store.commit("setChangePasswordErrors", {});
          this.$store.commit("setChangePasswordSuccess", false);
          this.$notify({
            // (optional)
            // Name of the notification holder
            group: "app",

            // (optional)
            // Class that will be assigned to the notification
            type: "success",

            // (optional)
            // Title (will be wrapped in div.notification-title)
            title: "Update",

            // Content (will be wrapped in div.notification-content)
            text: "Password updated successfully",

            // (optional)
            // Overrides default/provided duration
            duration: 10000,

            // (optional)
            // Overrides default/provided animation speed
            speed: 1000,
          });
        }
      });
    },
  },
};
</script>
