<template>
  <div>
    <div id="site-wrapper" v-if="user" class="site-wrapper panel my-profile">
      <!-- #header start -->
      <NavBar />
      <!-- #header end -->
      <!-- #wrapper-content start -->
      <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
        <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">
          <LeftSideBar />

          <div class="page-container">
            <div class="container-fluid">
              <div class="page-content-wrapper d-flex flex-column">
                <div class="row">
                  <div class="col-8">    <h1 class="font-size-h4 mb-4 font-weight-normal">My Profile</h1></div>
                  <div class="col-4">  <button @click="enableProfileClass" class="btn btn-info float-right">Edit</button></div>
                </div>
            
               
                <div class="row">
                  <div class="col-12"  :class="disabledProfileClass">
                    <Profile />
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>

      </div>
       <FooterBar />
      <!-- #wrapper-content end -->
    </div>

    <div v-else>
      <LoadingScreen />
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NavBar from "../Layouts/NavBar";
  import LeftSideBar from "../Layouts/LeftSideBar";
  import Profile from "../MyProfile/Profile";
  import FooterBar from "../Layouts/FooterBar";
  import LoadingScreen from "../Layouts/LoadingScreen";
  //
  export default {
    name: "MyProfile",
 data: function() {
    return {
      disabledProfileClass:'disabledProfile',
    };
  },
    components: {
      NavBar,
      LeftSideBar,
      Profile,
      FooterBar,
      LoadingScreen,
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },

    methods: {
      ...mapActions("auth"),
      enableProfileClass(){
        this.disabledProfileClass='';
      }
    },
  };
</script>
<style scoped>
.disabledProfile {
    pointer-events: none;
    opacity: 0.7;
}
</style>
