<template>
  <div>
   
    <div class="row">
      
      <div class="col-lg-6 mb-2">
        <div class="row">
          <div class="col-lg-12 mb-2">
            
            <ProfileLogo />
          </div>

          <div class="col-lg-12 mb-2">
            <Detail />
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-2">
        <div class="row">
          <div class="col-lg-12 mb-2">
            <ChangePassword />
          </div>

          <div class="col-lg-12 mb-2">
            <DeleteAccount />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Detail from "./Forms/Profile/Detail.vue";
import ChangePassword from "./Forms/Profile/ChangePassword";
import ProfileLogo from "./Forms/Profile/ProfileLogo";
import DeleteAccount from "./Forms/Profile/DeleteAccount";

export default {
  name: "Profile",
  components: {
    Detail,
    ChangePassword,
    ProfileLogo,
    DeleteAccount,
  },
};
</script>
